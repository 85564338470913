@import '../../../bps/components/form/form.scss';

.form {
  &__submit-button.button {
    background: $buttonPrimaryBackgroundColor;
    border-color: $buttonPrimaryBorderColor;
    color: $buttonPrimaryTextColor;

    &--hover,
    &:hover {
      background: $buttonPrimaryHoverBackgroundColor;
      color: $buttonPrimaryHoverTextColor;
    }
  }

  &__option-area {
    border-radius: 0 !important;
    background: $grayLighterColor;

    &:has(input:checked) { // stylelint-disable-line
      background: $whiteColor;
    }

    .info-popup__info {
      border: 1px solid $grayColor;

      &::before {
        transform: translate(-1px, -1px);
      }
    }
  }
}
